type LanguagesType = {
  name: string
  slug: string
  stationUrl: string
}[]

export const languages: LanguagesType = [
  {
    name: 'English',
    slug: 'english',
    stationUrl: 'http://stream.zeno.fm/uwfvscxsunhuv'
  },
  {
    name: 'Español',
    slug: 'espanol',
    stationUrl: 'http://stream.zeno.fm/sfkiyjhbxv7vv'
  },
  {
    name: 'Korean',
    slug: 'korean',
    stationUrl: 'http://stream.zeno.fm/uz5emgjq8tvvv'
  },
  {
    name: 'Français',
    slug: 'francais',
    stationUrl: 'http://stream.zeno.fm/iabknlvx2u1tv'
  }
];
