import { languages } from '../data/languages';
import Logo from '../assets/logo.svg';

export const Home = () => (
  <section className="bg-gray-100 w-full h-full flex flex-col items-center">
    <section className="mt-20">
      <img src={Logo} width={200} height={45} alt="Logo" />
    </section>

    <section className="w-96 flex flex-wrap items-center justify-center mt-20 mx-auto">
      {languages.map((language, idx) => (
        <a
          key={`language_${idx}`}
          href={language.stationUrl}
          className="bg-white w-40 h-40 flex rounded-lg shadow-lg p-4 text-center items-center justify-center font-bold text-lg m-2"
        >
          {language.name}
        </a>
      ))}
    </section>
  </section>
)
